import React, { useEffect, useState } from "react";
import { LegacyCard } from "@shopify/polaris";
import { useRouter } from "next/router";
import VerificationCodeInput from "@Molecules/common/auth/verificationCodeInput";
import VerifyEmailButton from "@Molecules/common/auth/verifyEmailButton";
import ResendEmailCode from "@Molecules/common/auth/resendEmailCode";
import Alreadyhaveanaccount from "@Molecules/common/auth/alreadyhaveanaccount";
import { useTranslation } from "next-i18next";
import SandboxVerifyCodeInstructions from "./sandboxVerifyCodeInstructions";

type Props = {
  refCode?: string;
  isTwoFactorAuthentication?: boolean;
};

const EmailMFAComponent = ({ isTwoFactorAuthentication, refCode }: Props) => {
  const [code, setCode] = useState("");
  const router = useRouter();

  const query: any = router?.query;

  const { t } = useTranslation("login");

  useEffect(() => {
    if (query?.code) {
      setCode(query?.code);
    }
  }, [query]);

  return (
    <div>
      <SandboxVerifyCodeInstructions />
      <LegacyCard sectioned title={t("verification_title")}>
        <VerificationCodeInput code={code} setCode={setCode} />
        <VerifyEmailButton
          code={code}
          isTwoFactorAuthentication={isTwoFactorAuthentication}
        />
      </LegacyCard>

      <ResendEmailCode refCode={refCode} />
      <Alreadyhaveanaccount />
    </div>
  );
};

EmailMFAComponent.defaultProps = {
  isTwoFactorAuthentication: false,
  refCode: "",
};

export default EmailMFAComponent;
