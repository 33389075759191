import { TextField } from "@shopify/polaris";
import React, { useCallback } from "react";
import { useTranslation } from "next-i18next";

type Props = {
  password: string;
  type?: any;
  setPassword: (email: string) => void;
};

export default function PasswordInput({ password, type, setPassword }: Props) {
  const hPasswordChange = useCallback((value) => setPassword(value), []);

  const { t } = useTranslation("login");

  return (
    <>
      <TextField
        value={password}
        onChange={hPasswordChange}
        label={t("password_label")}
        type={type}
        placeholder="******"
        autoComplete="password"
      />
      <br />
    </>
  );
}

PasswordInput.defaultProps = {
  type: "password",
};
