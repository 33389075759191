// Get axios error message
export const getAxiosErrorMessage = (error) => {
  if (!error) {
    return "Unknow error occurred";
  }

  const errorData = error?.response?.data;

  if (errorData?.message) {
    return errorData.message;
  }

  return error?.message || error || "Internal server error";
};

// Get react query error message
export const getReactQueryErrorMessage = (error) => {
  if (!error) {
    return "Unknow error occurred";
  }

  if (error?.data?.message) {
    return error.data.message;
  }

  return error?.message || "Internal server error";
};
