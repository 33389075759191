import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import createPersistedState from "use-persisted-state";

const useCountryState = createPersistedState("country");
const useCountryLastModifiedState = createPersistedState("countryLastModified");

const baseUrl = process.env.NEXT_PUBLIC_URL;

const useUserCountry = (nonAllowedCountries = ["us"]) => {
  const [country, setCountry] = useCountryState("");
  const [
    countryLastModified,
    setCountryLastModified,
  ] = useCountryLastModifiedState();
  const [loading, setLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isNotAllowed, setIsNotAllowed] = useState(false);

  useEffect(() => {
    fetchCountry();
  }, [country, countryLastModified]);

  const getProduction = () => {
    // If base url includes app, then this s a production environment
    try {
      const isProduction = baseUrl?.includes("app");

      return isProduction;
    } catch (error) {
      return false;
    }
  };

  const fetchCountry = async () => {
    const currentTime = moment();
    const lastModifiedTime = moment(countryLastModified);
    const isMoreThan24Hours = currentTime.diff(lastModifiedTime, "hours") >= 6;

    if (!country || !countryLastModified || isMoreThan24Hours) {
      await getUserIPAdress();
    } else {
      checkCountryAllowance();
    }
    setLoading(false);
  };

  const getUserIPAdress = async () => {
    try {
      const { data } = await axios.get("https://api.ipify.org/?format=json");
      const ip = data?.ip;
      await getInternalIP(ip);
    } catch (error) {
      console.error("Error getting IP", error);
    }
  };

  const storeCountry = (userCountry) => {
    if (!userCountry) {
      return null;
    }
    setCountry(userCountry);
    setCountryLastModified(moment().toISOString());
    checkCountryAllowance(userCountry);
  };

  const getLocation = async (ip) => {
    try {
      const { data } = await axios.get(`https://ipapi.co/${ip}/json/`);
      const userCountry = data?.country;
      storeCountry(userCountry);
    } catch (error) {
      getLocationFreeIpAPI(ip);
      console.error("Error getting location", error);
    }
  };

  const getLocationFreeIpAPI = async (ip) => {
    try {
      const { data } = await axios.get(`https://freeipapi.com/api/json/${ip}`);
      const userCountry = data?.countryCode;

      storeCountry(userCountry);
    } catch (error) {
      // TODO: Try to fetch it internally
      console.error("Error getting location", error);
    }
  };

  const getInternalIP = async (ip) => {
    try {
      const { data } = await axios.get(`/api/v1/ip`, {
        params: {
          ip,
        },
      });

      const countryCode = data?.country?.iso_code;

      storeCountry(countryCode);
    } catch (error) {
      // TODO: Try to fetch it internally
      console.error("Error getting location", error);
      getLocation(ip);
    }
  };

  const checkCountryAllowance = (currentCountry = country) => {
    const countryCode = currentCountry?.toLowerCase();
    setIsAllowed(!nonAllowedCountries.includes(countryCode));
    setIsNotAllowed(nonAllowedCountries.includes(countryCode));

    const isProduction = getProduction();

    if (!isProduction) {
      setIsAllowed(true);
      setIsNotAllowed(false);
    }
  };

  return { country, isAllowed, isNotAllowed, loading };
};

export default useUserCountry;
