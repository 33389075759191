import { fetchUserToken } from "@Libs";
import { Button } from "@shopify/polaris";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import { getAxiosErrorMessage } from "@Utils/errorUtils";
import useToast from "../hooks/usetoast";
import useFingerprint from "../hooks/useFingerPrint";

type Props = {
  code: string;
  isTwoFactorAuthentication?: boolean;
};

export default function VerifyEmailButton({
  code,
  isTwoFactorAuthentication,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fingerprint, device] = useFingerprint();
  const [toast, toggleToast] = useToast();
  const router = useRouter();

  const { t } = useTranslation("login");

  const verifyEmailCode = async () => {
    try {
      const token = await (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();

      setIsLoading(true);

      await axios({
        url: "/api/user/email/verifycode",
        method: "POST",
        data: {
          token,
          code,
          isTwoFactorAuthentication,
        },
      });

      let successMessage = "Email has been verified";

      if (isTwoFactorAuthentication) {
        successMessage = "Two factor authentication successful verified";
      }

      // Set user cookies and redirect to onboarding
      await fetchUserToken({ token, fingerprint, device });

      if (isTwoFactorAuthentication) {
        router.push("/user/dashboard");
        // setIsLoading(false);
        toggleToast(successMessage || "Email has been verified");

        return;
      }

      toggleToast(successMessage || "Email has been verified");
      router.push("/onboarding/stepone");
    } catch (error) {
      const errorMessage = getAxiosErrorMessage(error);
      setIsLoading(false);
      toggleToast(errorMessage, true);
    }
  };

  return (
    <>
      <Button
        loading={isLoading}
        onClick={verifyEmailCode}
        variant="primary"
        submit
      >
        {t("verify_button")}
      </Button>

      {toast}
    </>
  );
}

VerifyEmailButton.defaultProps = {
  isTwoFactorAuthentication: false,
};
