import { TextField } from "@shopify/polaris";
import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";

type Props = {
  code: string;
  setCode: (email: string) => void;
};

export default function VerificationCodeInput({ code, setCode }: Props) {
  const hCodeChange = useCallback((value) => setCode(value), []);

  const { t } = useTranslation("login");

  return (
    <>
      <TextField
        value={code}
        onChange={hCodeChange}
        label={t("code_label")}
        type="text"
        placeholder="123456"
        autoComplete="text"
      />
      <br />
    </>
  );
}
