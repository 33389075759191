import { Button, LegacyCard } from "@shopify/polaris";
import axios from "axios";
import Auth from "@aws-amplify/auth";
import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import useToast from "../hooks/usetoast";

type IProps = {
  refCode?: string;
};

export default function ResendEmailCode({ refCode }: IProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [toast, toggleToast] = useToast();

  const { t } = useTranslation("login");

  const resendEmailVerificationCode = async () => {
    try {
      setIsLoading(true);
      const token = await (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      await axios({
        url: "/api/user/email/verificationcode",
        method: "POST",
        data: {
          token,
          referralCode: refCode,
        },
      });
      setIsLoading(false);
      toggleToast("Email verification code has been sent");
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message;
      setIsLoading(false);
      toggleToast(errorMessage || "Error sending code", true);
    }
  };
  return (
    <LegacyCard sectioned>
      <Button loading={isLoading} onClick={resendEmailVerificationCode}>
        {t("resend_code_button")}
      </Button>
      {toast}
    </LegacyCard>
  );
}

ResendEmailCode.defaultProps = {
  refCode: "",
};
