import { useIsDev } from "@Selectors/userSelector";
import { LegacyCard } from "@shopify/polaris";
import { useTranslation } from "next-i18next";
import React from "react";

export default function SandboxVerifyCodeInstructions() {
  const isDev = useIsDev();

  const { t } = useTranslation("login");

  if (!isDev) {
    return null;
  }

  return (
    <LegacyCard sectioned>
      <p>
        {t("code_is_message")} <b>00000 </b>
      </p>
    </LegacyCard>
  );
}
