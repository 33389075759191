import { TextField } from "@shopify/polaris";
import React, { useCallback } from "react";

type Props = {
  label?: string;
  email: string;
  disabled?: boolean;
  setEmail?: (email: string) => void;
};

export default function EmailInput({
  email,
  label,
  disabled,
  setEmail,
}: Props) {
  const hEmailChange = useCallback((value) => setEmail(value), []);

  // TODO: Adding email validation here

  return (
    <>
      <TextField
        value={email}
        requiredIndicator
        onChange={hEmailChange}
        placeholder="Ex: info@pgecom.com"
        label={label}
        type="email"
        disabled={disabled}
        autoComplete="email"
      />
    </>
  );
}

EmailInput.defaultProps = {
  label: "Email",
  disabled: false,
  setEmail: () => {},
};
